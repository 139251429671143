import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import RichText from '../components/rich-text'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import TransitionLink from "../components/transition-link"

class CaseTemplate extends React.Component {
	constructor (props) {
		super(props);
		this.flexibleContent = React.createRef();
		this.marquee = React.createRef();
		this.marqueeLeft = React.createRef();
		this.marqueeRight = React.createRef();
	}

	componentDidMount () {
    	gsap.registerPlugin(ScrollTrigger);
    	this.flexibleContent.current.querySelectorAll('img').forEach(img => {
    		img.onload = () => ScrollTrigger.refresh();
    	});
    	let gsaps = [];
		Array.from(this.flexibleContent.current.children).forEach(div => {
			if (div.classList.contains('g-case__triple-image') || div.classList.contains('g-case__double-image')) {
				let opacity = 1;
				if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
					opacity = 0.8;
				}
				let anim = gsap.fromTo(div.querySelectorAll('img'), {
			      opacity:0,
			      y: 50
			    }, {
			      opacity: opacity,
			      y: 0,
			      duration: 1.5,
			      ease: 'circ.out',
			      stagger: 0.1,
			      scrollTrigger: {
			        once: true,
			        trigger: div,
			        start: 'top 75%'
			      }
			    });
			    gsaps.push(anim);
			} else {
				let anim = gsap.fromTo(div, {
			      opacity:0,
			      y: 100
			    }, {
			      opacity: 1,
			      y: 0,
			      duration: 1.5,
			      ease: 'circ.out',
			      scrollTrigger: {
			        once: true,
			        trigger: div,
			        start: 'top 75%'
			      }
			    });
			    gsaps.push(anim);
			}
		});

		// Animate marquee
		this.marqueeTween = gsap.from([this.marqueeLeft.current, this.marqueeRight.current], {
			xPercent: -100,
			duration: 40,
			repeat: -1,
			ease: 'none'
		});

		// If user reached end of the page, trigger all gsap animations
		window.addEventListener('scroll', () => {
			if (window.pageYOffset + window.innerHeight >= document.body.offsetHeight) {
				gsaps.forEach(anim => {
					if (anim.scrollTrigger) {
						anim.play();
					}
				});
				gsaps = [];
			}
		});

		// Update nav color
		const project = get(this.props, 'data.contentfulCase')
		const event = new CustomEvent('updateNavColor', {
			detail: {
				isBlack: project.navigationColor
			}
		});
		window.dispatchEvent(event);
	}

	onMarqueeEnter () {
		if (!this.marqueeTween) return;
		gsap.to(this.marqueeTween, {
			timeScale: 0,
			duration: 0.3
		});
	}
	onMarqueeLeave () {
		if (!this.marqueeTween) return;
		gsap.to(this.marqueeTween, {
			timeScale: 1,
			duration: 0.3
		});
	}

	shuffleArray(array) {
	  let i = array.length - 1;
	  for (; i > 0; i--) {
	    const j = Math.floor(Math.random() * (i + 1));
	    const temp = array[i];
	    array[i] = array[j];
	    array[j] = temp;
	  }
	  return array;
	}

	render() {
		const project = get(this.props, 'data.contentfulCase')
		const content = get(this.props, 'data.contentfulCase.contentBlocks')
		let marqueeCases = get(this.props, 'data.allContentfulCase.edges')
		marqueeCases = marqueeCases.filter(_case => _case.node.slug !== project.slug);
		marqueeCases = this.shuffleArray(marqueeCases).slice(0, 3);

		const options = {
		renderText: (text) => {
			return text
				.replace(/\u2028/g, "")
				.split("\n")
				.flatMap((text, i) => [i > 0 && <br key={i} />, text])
		}
	}

  	return (
  		<Layout location={this.props.location} title={`${project.clientName} | Lotte Bijlsma`} description={project.descriptiveTitle}>
	        <main className="g-case">
	        	<div className="c-cover">
	        		<img
	        			src={`${project.headerImage.file.url}?fit=pad&w=1200`}
	        			srcSet={`${project.headerImage.file.url}?fit=pad&w=2000 2000w, ${project.headerImage.file.url}?fit=pad&w=1200 1200w, ${project.headerImage.file.url}?fit=pad&w=600 600w`}
	        			/>
	        	</div>
	        	<section className="l-wrapper g-case__intro">
	        		<div className="g-case__intro-details">
				  		<span>{project.year}</span> - <span>{project.company}</span>
				  		<h1><a href={project.website} target="_blank" rel="noopener" className="o-link--external">{project.clientName}</a></h1>
	        		</div>
			  		<p className="o-title-medium g-case__descriptive-title">{project.descriptiveTitle}</p>
	        	</section>
	        	<section ref={this.flexibleContent}>
	        		{content.map((block, i) => {
	        			/* TEXT BLOCK */
	        			if (block.__typename === 'ContentfulBlockText') {
	        				return (
	        				<div key={i} className="g-case__text l-wrapper">
	        					<div className="g-case__text-container">
	        						{documentToReactComponents(block.text.json, options)}
	        					</div>
	        				</div>)
	        			}

	        			/* QUOTE BLOCK */
	        			if (block.__typename === 'ContentfulBlockQuote') {
	        				return (
	        				<div key={i} className="g-case__quote l-wrapper">
		        				<blockquote className="o-title-large">“{block.quote.quote}”</blockquote>
	        				</div>)
	        			}

	        			/* FULL WIDTH IMAGE BLOCK */
	        			if (block.__typename === 'ContentfulBlockMediaSingle') {
	        				return (
	        				<div key={i} className={"g-case__single-image " + (block.style === 'fullWidth' ? '' : 'l-wrapper is-contained')}>
	        					<img
				        			src={`${block.singleMedia.file.url}?fit=pad&w=1200`}
				        			srcSet={`${block.singleMedia.file.url}?fit=pad&w=2000 2000w, ${block.singleMedia.file.url}?fit=pad&w=1200 1200w, ${block.singleMedia.file.url}?fit=pad&w=600 600w`}
				        			/>
	        				</div>)
	        			}

	        			/* DOUBLE IMAGES BLOCK */
	        			if (block.__typename === 'ContentfulBlockMediaDouble') {
	        				return (
	        				<div key={i} className={"g-case__double-image l-wrapper " + (block.style === 'horizontalRight' ? 'is-reversed':'')}>
	        					{block.doubleMedia.map((media, i) => {
	        						return <img key={i} alt={!i%2 ? block.descriptionLeft : block.descriptionRight}
					        			src={`${media.file.url}?fit=pad&w=1200`}
					        			srcSet={`${media.file.url}?fit=pad&w=2000 2000w, ${media.file.url}?fit=pad&w=1200 1200w, ${media.file.url}?fit=pad&w=600 600w`}
					        			/>
	        					})}
	        				</div>)
	        			}

	        			/* TRIPLE IMAGES BLOCK */
	        			if (block.__typename === 'ContentfulBlockMediaTriple') {
	        				return (
	        				<div key={i} className={"g-case__triple-image l-wrapper " + (block.style === 'singleRight' ? 'is-offset':'')}>
	        					{block.tripleMedia.map((media, i) => {
	        						return <img key={i} alt={block['description' + (i + 1)]}
					        			src={`${media.file.url}?fit=pad&w=1200`}
					        			srcSet={`${media.file.url}?fit=pad&w=2000 2000w, ${media.file.url}?fit=pad&w=1200 1200w, ${media.file.url}?fit=pad&w=600 600w`}
					        			/>
	        					})}
	        				</div>)
	        			}

	        			/* CREDITS BLOCK */
	        			if (block.__typename === 'ContentfulBlockCredits') {
	        				return (
	        				<div key={i} className="g-case__credits l-wrapper">
		        				{documentToReactComponents(block.credits.json, options)}
	        				</div>)
	        			}
	        		})}
	        	</section>
	  		</main>
	       	<aside className="c-marquee" ref={this.marquee} onMouseEnter={() => this.onMarqueeEnter()} onMouseLeave={() => this.onMarqueeLeave()}>
	       		<div ref={this.marqueeLeft}>
		       		{marqueeCases.map((_case, i) => {
		       			return (
				      		<TransitionLink to={`/cases/${_case.node.slug}`} tiny={false} label={_case.node.clientName} key={i}/>
		       			)
		       		})}
	       		</div>
	       		<div ref={this.marqueeRight}>
		       		{marqueeCases.map((_case, i) => {
		       			return (
				      		<TransitionLink to={`/cases/${_case.node.slug}`} tiny={false} label={_case.node.clientName} key={i}/>
		       			)
		       		})}
	       		</div>
	       	</aside>
  		</Layout>
	)
  }
}

export default CaseTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
  	contentfulCase(slug: { eq: $slug }) {
	    id
	    slug
	    headerImage {
		  file {
		    url
		  }
		}
		website
		descriptiveTitle
		company
		clientName
		year
		navigationColor

		contentBlocks {
			... on ContentfulBlockQuote {
				id
				quote {
	        		id
		        	quote
		      	}
			}

			... on ContentfulBlockMediaSingle {
				singleMedia:media {
					file {
						url
					}
				}
				style
				description
			}

			... on ContentfulBlockMediaDouble {
				id
				doubleMedia:media {
					file {
						url
						fileName
					}
				}
				descriptionLeft
				descriptionRight
				style
			}

			... on ContentfulBlockMediaTriple {
				id
				tripleMedia:media {
					file {
						url
						fileName
					}
				}
				description1
				description2
				description3
				style
			}

	      	... on ContentfulBlockText {
		        id
		        text {
		        	json
		        }
	      	}

			... on ContentfulBlockCredits {
				id
				credits {
					... on contentfulBlockCreditsCreditsRichTextNode {
						json
					}
				}
			}
	    }
	}
	allContentfulCase {
      edges {
        node {
          clientName
          slug
        }
      }
    }
  }
`