import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default ({ content, classes = [] }) => {
	classes.push('o-rich-text');
	content = content.replace(/\n/g, "<br>");

	return (
		<div className={classes.join(' ')} dangerouslySetInnerHTML={{
			__html: content
		}}></div>
	)
}